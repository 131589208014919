.vi-container {
  width: 100%;
  display: flex;
  background-color: transparent;
}

.vi-content {
  overflow-y: scroll;
  width: auto;
  padding: 6.5rem;
  background-color: #f0f3f8;

  @media all and (max-width: 768px) {
    padding: 3rem;
  }

  @media all and (max-width: 575px) {
    padding: 1.5rem;
  }
}

.width-body-show-nav {
  width: calc(100% - 25rem);
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.width-body-hide-nav {
  width: calc(100% - 8rem);
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.ent-full {
  width: 100%;
  float: left;
}

footer {
  background: #fcaf17;
}

.section-footer2 {
  padding-top: 70px;
  padding-bottom: 40px;
}

.footer1 {
  width: calc(35% - 30px);
}

.footer3 {
  width: 25%;
}

.title-menu {
  text-transform: uppercase;
  cursor: default;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  position: relative;
  margin-bottom: 40px;
}

.title-menu:before {
  position: absolute;
  content: "";
  height: 3px;
  width: 60px;
  bottom: -10px;
  background: #fff;
}

.textwidget {
  font-size: 15px;
  color: #8f0505;
}

.footer2 {
  width: calc(20% - 30px);
}

.textwidget ul {
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
}

.copyright {
  width: 100%;
}

.copyright p {
  text-align: center;
  margin: 0px;
  padding: 15px 0px;
  background: #000;
  color: #fff;
}

.textwidget-icons img {
  width: 21px;
  height: auto;
}

.member_gr {
  margin-top: 50px;
  color: #fff;
  clear: both;
}

.member_gr img {
  width: 100px;
  margin-left: 10px;
}

.social-icons i {
  z-index: 3;
  position: relative;
  line-height: 37px;
}

.textwidget i {
  margin-right: 5px;
  width: 20px;
}

.label-company {
  text-transform: uppercase;
  font-size: 17px;
  font-weight: 500;
}

.gtx-trans {
  color: #fff;
}

li {
  list-style: none;
}

.ent-container {
  width: 100%;
  max-width: 1320px;
  margin: auto;
  clear: both;
}

.ent-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media (min-width: 768px) and (max-width: 1366px) {
  .ent-container {
    max-width: 1200px;
  }
}

@media (max-width: 767px) {
  .ent-container {
    max-width: 650px;
    padding: 0px 5px;
  }

  .footer1,
  .footer2,
  .footer3 {
    width: 100%;
    margin-top: 20px;
  }

  footer {
    padding: 20px 0px 0px 0px;
  }

  .ent-top {
    display: none;
  }

  .ent-top-right {
    width: 100%;
    text-align: center;
    justify-content: space-between;
  }

  #header-contact {
    width: 100%;
  }

  #header-contact .navli:last-child {
    margin-right: 0;
  }

  .logo-qhome {
    max-width: 50%;
  }

  .footer3 {
    width: 100%;
  }

  .member_gr {
    margin-top: 20px;
  }

  .title-menu {
    margin-bottom: 20px;
  }

  .copyright p {
    margin-top: 1rem;
  }
}