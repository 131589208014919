@import '../utils/color.scss';
.header {
  width: 100vw;
  height: 8rem;
  background-color: $color-white;
  margin: 0 auto;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  position: fixed;
  z-index: 99;
}
.header-body {
  width: 100%;
  height: 100%;
  align-items: center;
}
.content-header {
  width: 92%;
  height: 100%;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
  // padding-left: 3.75rem;
  // padding-right: 3.75rem;

  .header-right {
    .ant-btn {
      display: flex;
      align-items: center;
    }

    .hamburger {
      display: none;

      @media screen and (max-width: 768px) {
        display: block;

        .hamburger-button {
          margin: 0px 10px;
        }
      }

      @media screen and (max-width: 575px) {
        display: block;

        .hamburger-button {
          margin: 0px 8px;
        }
      }
    }
  }

  .header-action {
    display: flex;
    .btn-avatar {
      display: flex;
      vertical-align: middle;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .btn-language {
      padding: 0 15px !important;
      display: flex;
      align-items: center;
      justify-content: center;
      vertical-align: middle;

      @media screen and (max-width: 768px) {
        padding: 0 8px !important;
      }
    }

    .icon-national-flag {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .ant-form-item {
      margin-bottom: 0px !important;
    }
  }

  .dropdown-divider {
    height: 0;
    margin: 0.5rem 0;
    overflow: hidden;
    border-top: 1px solid #e2e8f2;
  }

  .logo {
    width: auto;
    object-fit: contain;

    &__shape {
      height: 50px;
    }
    &__text {
      height: 25px;

      @media screen and (max-width: 575px) {
        display: none;
      }
    }
  }
}

.content-header-logo {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.navbar {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar-container {
  width: 80%;
}

.logo {
  height: 70px;
  width: auto;
  object-fit: contain;
  display: flex;

  p {
    padding: 8px 4px;
  }
}

.menu-icon {
  display: none;
}

.nav-elements {
}

.nav-elements ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
}

.nav-elements ul li:not(:last-child) {
  margin-right: 30px;
}

:where(.css-dev-only-do-not-override-156uwm5).ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
  margin: 1rem;
}

:where(.css-dev-only-do-not-override-156uwm5).ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover {
  background-color: transparent !important;
  color: rgb(8, 155, 45);
}

.nav-elements ul a {
  font-size: 15px;
  font-weight: 700;
  color: #474242;
  text-decoration: none;
  text-transform: uppercase;
}

.nav-elements ul a.active {
  color: #833636;
  font-weight: 700;
  position: relative;
}

.nav-elements ul a.active::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #574c4c;
}

@media (max-width: 1200px) {
  .nav-elements ul li:not(:last-child) {
    margin-right: 28px;
  }
  .nav-elements ul a {
    font-size: 12px;
  }
}

@media (max-width: 768px) {
  .nav-elements ul li:not(:last-child) {
    margin-right: 20px;
  }
  .nav-elements ul a {
    font-size: 12px;
  }
}

@media (max-width: 600px) {
  .menu-icon {
    display: block;
    cursor: pointer;
  }

  .menu-icon > span > svg {
    width: 25px;
    height: 25px;
  }

  .nav-elements {
    position: absolute;
    right: 0;
    top: 8rem;
    background-color: #f9fff9;
    width: 0px;
    height: calc(100vh - 60px);
    transition: all 0.3s ease-in;
    overflow: hidden;
    box-shadow: 0px 0px 7px 1px #f9f9f9;
  }

  .nav-elements.active {
    width: 270px;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
  }

  .nav-elements ul li {
    margin-right: unset;
    margin-top: 22px;
  }

  .nav-elements ul a {
    font-size: 13px;
  }
}

