@keyframes heartbeat {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

.support_after {
    width: 134px !important;
    height: 134px !important;
    background: rgba(152, 147, 147, 0.37) !important;
}

.support {
  position: fixed;
  bottom: 50px;
  right: 56px;
  width: 67px;
  height: 67px;
  background: transparent;
  border-radius: 15px;
  transition: all linear 0.3s;
  z-index: 1;

  .row {
    margin: 0 !important;
    padding: 0 !important;
  }
  
  .support-item-hidden{
    visibility: hidden;
    opacity: 0;
    transition: all linear 0.3s;
  }

  .support-item-show{
    visibility: visible;
    opacity: 1;
    transition: all linear 0.3s;
  }

  .support-user-hidden{
    z-index: -1;
    visibility: hidden;
  } 

  .support-user-show{
    z-index: 1;
    visibility: visible;
  }

  .p-0 {
    padding: 0px !important;
  }

  .col-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .support-user {
    width: 67px;
    height: 67px;
    position: absolute;
    bottom: 50px;
    right: 50px;
    transition: all linear 0.3s;
  }

  .coccoc-alo-phone {
    background-color: transparent;
    width: 200px;
    height: 200px;
    cursor: pointer;
    z-index: 200000 !important;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translateZ(0);
    -webkit-transition: visibility .5s;
    -moz-transition: visibility .5s;
    -o-transition: visibility .5s;
    transition: visibility .5s;
    right: 150px;
    top: 30px;
  }

  .coccoc-alo-phone.coccoc-alo-green .coccoc-alo-ph-circle {
    border-color: #1c82ff;
    opacity: .5;
  }

  .coccoc-alo-ph-circle {
    width: 160px;
    height: 160px;
    top: 20px;
    left: 20px;
    position: absolute;
    background-color: transparent;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    border: 2px solid rgba(30, 30, 30, 0.4);
    opacity: .1;
    -webkit-animation: coccoc-alo-circle-anim 1.2s infinite ease-in-out;
    -moz-animation: coccoc-alo-circle-anim 1.2s infinite ease-in-out;
    -ms-animation: coccoc-alo-circle-anim 1.2s infinite ease-in-out;
    -o-animation: coccoc-alo-circle-anim 1.2s infinite ease-in-out;
    animation: coccoc-alo-circle-anim 1.2s infinite ease-in-out;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
  }

  .coccoc-alo-phone.coccoc-alo-green .coccoc-alo-ph-circle-fill {
    background-color: rgba(0, 175, 242, 0.5);
    opacity: .95 !important;
  }

  .coccoc-alo-ph-circle-fill {
    width: 100px;
    height: 100px;
    top: 50px;
    left: 50px;
    position: absolute;
    background-color: #000;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    border: 2px solid transparent;
    opacity: .1;
    -webkit-animation: coccoc-alo-circle-fill-anim 2.3s infinite ease-in-out;
    -moz-animation: coccoc-alo-circle-fill-anim 2.3s infinite ease-in-out;
    -ms-animation: coccoc-alo-circle-fill-anim 2.3s infinite ease-in-out;
    -o-animation: coccoc-alo-circle-fill-anim 2.3s infinite ease-in-out;
    animation: coccoc-alo-circle-fill-anim 2.3s infinite ease-in-out;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
  }

  .coccoc-alo-phone.coccoc-alo-green .coccoc-alo-ph-img-circle {
    background-color: #1c82ff;
  }

  .coccoc-alo-ph-img-circle {
    width: 60px;
    height: 60px;
    top: 70px;
    left: 70px;
    position: absolute;
    background: rgba(30, 30, 30, 0.1) url(../../assets/icons/sp_phone.png) no-repeat center center;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    border: 2px solid transparent;
    opacity: 1;
    -webkit-animation: coccoc-alo-circle-img-anim 1s infinite ease-in-out;
    -moz-animation: coccoc-alo-circle-img-anim 1s infinite ease-in-out;
    -ms-animation: coccoc-alo-circle-img-anim 1s infinite ease-in-out;
    -o-animation: coccoc-alo-circle-img-anim 1s infinite ease-in-out;
    animation: coccoc-alo-circle-img-anim 1s infinite ease-in-out;
  }

  .support img {
    width: 100%;
    -o-object-fit: contain;
    object-fit: contain;
    transform: scale(1);
    transition: all linear 0.3s;
    transform: scale(1);
    cursor: pointer;
  }
}


@-moz-keyframes coccoc-alo-circle-anim {
  0% {
      transform: rotate(0) scale(.5) skew(1deg);
      opacity: .1
  }

  30% {
      transform: rotate(0) scale(.7) skew(1deg);
      opacity: .5
  }

  100% {
      transform: rotate(0) scale(1) skew(1deg);
      opacity: .1
  }
}

@-webkit-keyframes coccoc-alo-circle-anim {
  0% {
      transform: rotate(0) scale(.5) skew(1deg);
      opacity: .1
  }

  30% {
      transform: rotate(0) scale(.7) skew(1deg);
      opacity: .5
  }

  100% {
      transform: rotate(0) scale(1) skew(1deg);
      opacity: .1
  }
}

@-o-keyframes coccoc-alo-circle-anim {
  0% {
      transform: rotate(0) scale(.5) skew(1deg);
      opacity: .1
  }

  30% {
      transform: rotate(0) scale(.7) skew(1deg);
      opacity: .5
  }

  100% {
      transform: rotate(0) scale(1) skew(1deg);
      opacity: .1
  }
}

@keyframes coccoc-alo-circle-anim {
  0% {
      transform: rotate(0) scale(.5) skew(1deg);
      opacity: .1
  }

  30% {
      transform: rotate(0) scale(.7) skew(1deg);
      opacity: .5
  }

  100% {
      transform: rotate(0) scale(1) skew(1deg);
      opacity: .1
  }
}

@-moz-keyframes coccoc-alo-circle-fill-anim {
  0% {
      transform: rotate(0) scale(.7) skew(1deg);
      opacity: .2
  }

  50% {
      transform: rotate(0) scale(1) skew(1deg);
      opacity: .2
  }

  100% {
      transform: rotate(0) scale(.7) skew(1deg);
      opacity: .2
  }
}

@-webkit-keyframes coccoc-alo-circle-fill-anim {
  0% {
      transform: rotate(0) scale(.7) skew(1deg);
      opacity: .2
  }

  50% {
      transform: rotate(0) scale(1) skew(1deg);
      opacity: .2
  }

  100% {
      transform: rotate(0) scale(.7) skew(1deg);
      opacity: .2
  }
}

@-o-keyframes coccoc-alo-circle-fill-anim {
  0% {
      transform: rotate(0) scale(.7) skew(1deg);
      opacity: .2
  }

  50% {
      transform: rotate(0) scale(1) skew(1deg);
      opacity: .2
  }

  100% {
      transform: rotate(0) scale(.7) skew(1deg);
      opacity: .2
  }
}

@keyframes coccoc-alo-circle-fill-anim {
  0% {
      transform: rotate(0) scale(.7) skew(1deg);
      opacity: .2
  }

  50% {
      transform: rotate(0) scale(1) skew(1deg);
      opacity: .2
  }

  100% {
      transform: rotate(0) scale(.7) skew(1deg);
      opacity: .2
  }
}

@-moz-keyframes coccoc-alo-circle-img-anim {
  0% {
      transform: rotate(0) scale(1) skew(1deg)
  }

  10% {
      transform: rotate(-25deg) scale(1) skew(1deg)
  }

  20% {
      transform: rotate(25deg) scale(1) skew(1deg)
  }

  30% {
      transform: rotate(-25deg) scale(1) skew(1deg)
  }

  40% {
      transform: rotate(25deg) scale(1) skew(1deg)
  }

  50% {
      transform: rotate(0) scale(1) skew(1deg)
  }

  100% {
      transform: rotate(0) scale(1) skew(1deg)
  }
}

@-webkit-keyframes coccoc-alo-circle-img-anim {
  0% {
      transform: rotate(0) scale(1) skew(1deg)
  }

  10% {
      transform: rotate(-25deg) scale(1) skew(1deg)
  }

  20% {
      transform: rotate(25deg) scale(1) skew(1deg)
  }

  30% {
      transform: rotate(-25deg) scale(1) skew(1deg)
  }

  40% {
      transform: rotate(25deg) scale(1) skew(1deg)
  }

  50% {
      transform: rotate(0) scale(1) skew(1deg)
  }

  100% {
      transform: rotate(0) scale(1) skew(1deg)
  }
}

@-o-keyframes coccoc-alo-circle-img-anim {
  0% {
      transform: rotate(0) scale(1) skew(1deg)
  }

  10% {
      transform: rotate(-25deg) scale(1) skew(1deg)
  }

  20% {
      transform: rotate(25deg) scale(1) skew(1deg)
  }

  30% {
      transform: rotate(-25deg) scale(1) skew(1deg)
  }

  40% {
      transform: rotate(25deg) scale(1) skew(1deg)
  }

  50% {
      transform: rotate(0) scale(1) skew(1deg)
  }

  100% {
      transform: rotate(0) scale(1) skew(1deg)
  }
}

@keyframes coccoc-alo-circle-img-anim {
  0% {
      transform: rotate(0) scale(1) skew(1deg)
  }

  10% {
      transform: rotate(-25deg) scale(1) skew(1deg)
  }

  20% {
      transform: rotate(25deg) scale(1) skew(1deg)
  }

  30% {
      transform: rotate(-25deg) scale(1) skew(1deg)
  }

  40% {
      transform: rotate(25deg) scale(1) skew(1deg)
  }

  50% {
      transform: rotate(0) scale(1) skew(1deg)
  }

  100% {
      transform: rotate(0) scale(1) skew(1deg)
  }
}


.go-up {
  position: fixed;
  bottom: 150px;
  right: 20px;
  width: 38px;
  height: 28px;
  background: #f1eeeea4;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  z-index: 1000;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  img {
    width: 30px;
    height: 30px;
  }
}