* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
  font-family: 'Inter', sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  text-rendering: optimizespeed;
  color: #161823;
  overflow-y: overlay;
}
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}

.container {
  width: 100%;
  min-height: 100vh;
  max-width: 100%;
  padding: 0;
}

.content {
  flex: 1;
}
a {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 19px;
  color: rgb(255, 0, 38);
  text-decoration: none;
  cursor: pointer;
}

p {
  padding: 0;
  margin: 0;
  word-wrap: break-word;
  word-break: break-word;
  white-space: pre-line;
}
