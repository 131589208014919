$color-white: #fff;
$color-white-gray: #dfdfdf;
$color-white-lighten: #f7f7f7;
$color-white-opacity: rgba(255, 255, 255, 0);

$color-dark-black: #000000;
$color-black: #222222;
$color-black-opacity: rgba(0, 0, 0, 0.5);
$color-white-opacity: rgba(0, 0, 0, 0.1);
$color-text-official: #054d9e;

$color-official: #f59e08;
$color-black: #000000;
$color-nearly-black: #111827;
$color-gray: #6b7280;
$color-red: #ef4444;

$background-yellow: #f59e08;
$background-official: #f3f4f6;
$background-purple: #14171e;
$background-hover: #f9fafb;

$border-color: #e5e7eb;
