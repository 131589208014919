@import 'normalize.css';
:root {
  --primary: #fe2c55;
  --black: #000;
  --white: #fff;
  --text-color: #496057;
  --color-error: rgb(255, 0, 38);
  --font-family: 'Inter', sans-serif;
  --color-border: #d9d9d9; // Default layout
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  line-height: 22.5px;
}

html {
  font-size: 60.5%;
}

body {
  font-family: var(--font-family) !important;
  font-size: 1.6rem;
  line-height: 1.5;
  text-rendering: optimizespeed;
  color: var(--text-color);
  overflow-y: overlay;
  background-image: linear-gradient(rgb(249 248 248), white);
}

h1 {
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 1.2;
  color: var(--black);
}

// Scrollbar CSS
html *::-webkit-scrollbar {
  border-radius: 0;
  width: 8px;
}

html *::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.15);
}

html *::-webkit-scrollbar-track {
  border-radius: 0;
  background-color: rgba(0, 0, 0, 0);
}

a {
  color: #aa0606;
  text-decoration: none;
}

body {
  .shadow-border {
    border-bottom: 1px solid #fff;
    box-shadow: 0 0 10px rgb(0 0 0 / 8%);
  }
}
.virtual-headers {
  height: 8rem;
  width: 100vw;
}
.ant-tooltip {
  font-family: var(--font-family) !important;
}

.vi-type__item {
  display: flex;

  span {
    font-size: 16px;
    font-weight: 600;
  }
  &--img {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin-left: 12px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
.ant-btn-text:not(:disabled):not(.ant-btn-disabled):hover {
  // background-color: #fe2c55;
  transition: background-color 0.3s ease;
}

.ant-form-item-required::after {
  visibility: hidden !important;
}

.ant-form-item-required::before {
  content: '*';
  position: absolute;
  right: -2px;
  top: 15px;
}

.ant-pagination-item {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.ant-pagination .ant-pagination-item {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

// .ant-spin-spinning {
//   width: 100% !important;
//   height: 100% !important;
//   min-height: 100vh !important;
//   background-color: #f9fafb !important;
//   opacity: 0.5 !important;
//   position: absolute !important;
//   top: 0 !important;
//   left: 0 !important;

//   .ant-spin-dot {
//     position: absolute !important;
//     top: 50% !important;
//     left: 50% !important;
//     transform: translate(-50%, -50%) !important;
//   }
// }
.via-collapse-header {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  background: #d262e3 linear-gradient(135deg, #d262e3 0, #0665d0 100%) !important;
  border-radius: 0.25rem !important;
  width: 100% !important;
  .ant-collapse-expand-icon {
    color: white;
  }

  .ant-collapse-header-text {
    width: calc(100% - 25px);
  }
}

.evo-product-policy {
  margin-bottom: 10px;
  margin-top: 20px;
  border: 1px solid #f3f4f6;
  padding: 10px;
  border-radius: 4px;
}

.evo-product-policy h4 {
  border-bottom: 1px solid #f3f4f6;
  font-size: 16px;
  padding-bottom: 8px;
}

.evo-product-policy .evo-product-policy-item {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.evo-product-policy .evo-product-policy-item img {
  max-width: 30px;
  margin-right: 10px;
}

.btn-disable {
  background-color: #f1f2f3;
  border-color: #f3f4f6;
  color: #c4c4c4;
  cursor: not-allowed;
  &:hover {
    background-color: #f3f4f6;
    border-color: #f3f4f6;
    color: #c4c4c4;
  }
}

:where(.css-dev-only-do-not-override-156uwm5).ant-carousel .slick-dots-bottom {
  bottom: 26px !important;
}
